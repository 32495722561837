import React, {useState} from 'react'
import {Link} from 'gatsby'
import { FaAlignJustify } from "react-icons/fa"
import logo from "../assets/images/logo2.svg"

const Navbar = () => {
    const [show,setShow] = useState(false)
    return ( 
        <nav className="navbar">
            <div className="nav-center">
                <div className="nav-header">
                    <Link to="/">
                        <img src={logo} alt="simply recipes" />                    
                    </Link>
                    <button className="nav-btn" onClick={()=> setShow(!show)}>
                        <FaAlignJustify />
                    </button>
                </div>
                <div className={show ? "nav-links show-links" : "nav-links"}>
                <Link to="/" className="nav-link" 
                activeClassName="active-link"
                onClick={()=> setShow(false)}>
                    home
                </Link>
                <Link to="/services" className="nav-link" 
                activeClassName="active-link"
                onClick={()=> setShow(false)}>
                    Diensten
                </Link>
                <Link to="/projects" className="nav-link" 
                activeClassName="active-link"
                onClick={()=> setShow(false)}>
                   Projecten
                </Link>
                <Link to="/portfolio" className="nav-link" 
                activeClassName="active-link"
                onClick={()=> setShow(false)}>
                    Portfolio
                </Link>
                <Link to="/costopt" className="nav-link" 
                activeClassName="active-link"
                onClick={()=> setShow(false)}>
                    Besparen
                </Link>
                <div className="nav-link contact-link">
                <Link to="/contact" className="btn" 
                onClick={()=> setShow(false)}>
                   contact
                </Link>
                </div>
                </div>
                </div>
        </nav>    
    )
}

export default Navbar
